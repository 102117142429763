<template>
  <div class="user-management">
    <div class="user-management__delete" v-if="isDelete">
      <img src="@/assets/svg/icon-delete-member.svg" />
    </div>
    <router-link v-if="getUser" :to="`${ getUser?.id === data?.id ? '/profile' : '/user/' + data?.id }`"  class="content">
      <div class="content__left">
        <img class="content__left-avatar" :src="getUserAvatar(data.avatarSrc.fileSrc)" alt="">
        <div class="content__left-info">
          <p class="info-nickname">{{ data.login }}</p>
          <div class="info-roll">
            <img :src="getUserRole(data?.role).icon || null" alt="">
            <p>{{ getUserRole(data?.role).text || null }}</p>
          </div>
          <div class="content__left-info-location">
            <div class="location">
              <img src="../../../assets/svg/icon-location.svg" alt="" />
              <p>{{ data?.region?.name || data?.city || $t('empty') }}</p>
            </div>
            <p class="location-text" v-if="getUser?.role !== 'Company' && getUser.role !== 'Government'">{{ $t('length-requests-closed-total', {from: data.acceptedOrdersCount , to: data.totalOrdersCount}) }}</p>
          </div>
        </div>
      </div>
      <div class="content__right">
        <div class="content__right-location">
          <div class="location">
            <img src="../../../assets/svg/icon-location.svg" alt="" />
            <p>{{ data?.region?.name || data?.city || $t('empty') }}</p>
          </div>
          <p class="location-text" v-if="getUser?.role !== 'Company' && getUser?.role !== 'Government'">{{ $t('length-requests-closed-total', {from: data.acceptedOrdersCount , to: data.totalOrdersCount}) }}</p>
        </div>
        <div class="content__right-rating" v-if="data?.role === 'Inspector'">
          <ui-rating v-model="data.starRating" />
          <p class="rating-chip">{{ data.score }}</p>
        </div>
      </div>
    </router-link>
    <div v-if="data.status === 'New' && visibleActions && !isDelete" class="user-management__action">
      <ui-button @click="acceptUser">{{ $t('accept') }}</ui-button>
      <ui-button @click="declineUser" color="error">{{ $t('reject') }}</ui-button>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { getUserAvatar, getUserRole } from '@/utils/user'

export default {
  name: "UserManagementCard",
  components: {
    UiButton: () => import('@/components/ui/UiButton'),
    UiRating: () => import('@/components/ui/UiRating')
  },

  props: {
    data: {
      type: Object,
      default: () => {}
    },
    showAction: {
      type: Boolean,
      default: false
    },
    isDelete: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      getUserAvatar,
      getUserRole,
      visibleActions: true,
    }
  },

  methods: {
    ...mapActions(['userAccept', 'userDecline']),

    getUserName(name, surname) {
      if(name && surname) {
        if(name.split(' ').length >= 2) {
          return name.split(' ')[0] + ' ' +  name.split(' ')[1].substring(0, 1).toUpperCase() + '.' + surname.substring(0, 1).toUpperCase() + '.'
        }
      }
      return name + ' ' + surname
    },

    acceptUser() {
      this.userAccept(this.data.id).then(() => {
        this.visibleActions = false
      })
    },

    declineUser() {
      this.userDecline(this.data.id).then(() => {
        this.visibleActions = false
      })
    },
  },

  computed: {
    ...mapGetters(['getUser']),

    // getTitle() {
    //   return this.data?.role === 'Inspector' || this.data?.role === 'Lawyer' ? `${ this.getUserName(this.data.firstName, this.data.lastName) }`  : '' ||
    //   this.data?.role === 'Company' ? this.data.companyName : '' ||
    //   this.data?.role === 'Government' ? this.data.companyName : ''
    // },
  }
}
</script>

<style lang="scss" scoped>
.user-management {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
  position: relative;

  &__delete {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    z-index: 10;

    img {
      position: absolute;
      left: 40px;
      top: 26px;
      z-index: 11;

      @media (max-width: 992px) {
        width: 32px;
        height: 32px;
        left: 22px;
        top: 30px;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 20px 11px 20px;
    background: #FFFFFF;
    box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
    border-radius: 200px;
    width: 100%;

    @media (max-width: 992px) {
      padding: 17px 20px 11px 10px;
      height: 85px;
    }

    &__left {
      display: flex;
      align-items: center;
      gap: 20px;
      max-width: 394px;
      width: 100%;

      @media (max-width: 992px) {
        gap: 10px;
      }

      &-avatar {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        object-fit: cover;

        @media (max-width: 992px) {
          width: 50px;
          height: 50px;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 992px) {
          gap: 5px;
        }
        .info-nickname {
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #343432;
          font-family: "Gotham Pro Medium";
        }

        .info-roll {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 5px;
          white-space: nowrap;

          img {
            width: 16px;
            height: 16px;

            @media (max-width: 992px) {
              display: none;
            }
          }

          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 120.2%;
            color: #343432;
            font-family: "Gotham Pro Regular";

            @media (max-width: 992px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 11px;
              color: #9A9A9A;
            }
          }

          span {
            display: flex;
            align-items: center;
            gap: 5px;
            font-weight: 400;
            font-size: 14px;
            line-height: 120.2%;
            color: #343432;

            @media (max-width: 992px) {
              font-weight: 400;
              font-size: 12px;
              line-height: 11px;
              color: #9A9A9A;
            }
          }
        }

        &-location{
          display: none;
          align-items: center;
          gap: 10px;

          @media (max-width: 992px) {
            display: flex;
            margin-top: 5px;
          }
          .location {
            display: flex;
            align-items: center;
            gap: 5px;

            img {
              width: 8px;
              height: 10px;
            }

            p {
              font-weight: 400;
              font-size: 10px;
              line-height: 10px;
              color: #343432;
              font-family: "Gotham Pro Regular";
            }
          }

          .location-text{
            font-weight: 400;
            font-size: 10px;
            line-height: 10px;
            color: #9A9A9A;
            font-family: "Gotham Pro Regular";
          }
        }
      }
    }
    &__right {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      gap: 10px;

      @media (max-width: 992px) {
        width: auto;
      }

      &-location{
        display: flex;
        align-items: center;
        gap: 90px;

        @media (max-width: 992px) {
          display: none;
        }
        .location {
          display: flex;
          align-items: center;
          gap: 5px;
          width: 100px;

          p {
            font-family: "Gotham Pro Regular";
            font-size: 16px;
            font-weight: 400;
            line-height: 120.2%;
          }
        }

        .location-text{
          font-weight: 400;
          font-size: 16px;
          line-height: 120.2%;
          color: #9A9A9A;
          max-width: 140px;
          text-align: center;
          width: 100%;font-family: "Gotham Pro Regular";
        }
      }

      &-rating {
        display: flex;
        align-items: center;
        gap: 50px;

        @media (max-width: 992px) {
          flex-direction: column;
          gap: 8px;
        }
        .rating-chip {
          padding: 14px 14px 11px 14px;
          background: #E21F1F;
          font-weight: 400;
          font-size: 16px;
          line-height: 15px;
          color: #FFFFFF;
          box-shadow: 2px 2px 10px rgba(153, 153, 153, 0.2);
          border-radius: 200px;;
        }
      }
    }
  }
  &__action {
    display: flex;
    padding-right: 20px;
    gap: 10px;
    min-width: 244px;

    @media (max-width: 992px) {
      width: 100%;
      padding: 0 12px;
    }
    button {
      height: 30px;

      @media (max-width: 992px) {
        height: 40px;
      }
    }
  }
}
::v-deep .ui-rating {
  img {
    width: 25px !important;
    height: 25px !important;

    @media(max-width: 768px) {
      width: 14px !important;
      height: 14px !important;
    }
  }
}
</style>
